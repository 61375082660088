.bottom-base{
	/*justify-self: flex-end;*/
	grid-column: 1 / 3;
	grid-row: 2 / 3;
	align-self: end;
	border-radius: 10px 10px 0 0;
	/*background: #fffffff8;*/
	width: 100%;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 20px 20px;
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-size: 17px;
	line-height: 1.8;
	text-align: center;
	height: 100%;
	margin: auto;
	z-index: 1;
}
.bottom-base > .contain{
	/*display: flex;*/
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: grid;
	width: 100%;
	grid-template-columns: 1fr;
}

.bottom-base p {
	margin-top: 25px;
}

.bottom-base p:first-child{
	margin-top: 0;
}
