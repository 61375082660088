/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');*/

/** définir via url permet l'utilisation du sw */

body * {
	box-sizing: border-box;
}

main{
	width: 100%;
	height: 100%;
	font-family: 'Poppins','Open Sans', sans-serif;
}

.background-scr-prod{
	grid-column: 1 / 3;
	margin: auto;
	min-height: 100vh;
	max-width:640px;
	height: 100%;
	width: 100%;
	position: relative;
	background: black;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	overflow: hidden;
}

.background-scr-prod{
	min-height: 100vh;
	max-width:640px;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 60px;
	grid-template-rows: minmax(141px, 1fr) min-content;
	justify-content: center;
	align-items: center;
	position: relative;
}

.logo-scr-prod{
	/*position: absolute;*/
	max-width: 250px;
	width: 100%;
	/*top: 100px;*/
	margin: auto;
	grid-column: 1 / 3;
	grid-row: 1 / 2;
}

/** à mettre dans un fichier spécifique */

section .selected{
	background-color: #cfb77f;
	color: #000;
}
