.event-container {
	display: grid;
	grid-template-columns: 1fr 80px minmax(min-content, 300px) 1fr;
	grid-template-rows: 1fr min-content min-content 4fr min-content 1fr;
	/*max-height: 200px;*/
	gap: 0.5rem;
	width: 100%;
	max-width: 420px;
}

.event-container .image-event {
	grid-column: 2 / 3;
	grid-row: 2 / 6;
	width: 100%;
	height: 100%;
	justify-self: center;
	align-self: center;
	background-color: #ffffff77;
}

.image-event img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.event-container .date-de-visite-container,
.event-container .instance-type-evaluable-nom,
.event-container .lieu,
.event-container .questionnaire-container{
	text-align:left;
	grid-column: 3 / 4;
	justify-self: start;
	align-self: center;
	font-size: 14px;
}

.event-container .date-de-visite-container {
	grid-row: 2 / 3;
	font-weight: 200;
	letter-spacing: 1px;
}

.event-container .instance-type-evaluable-nom {
	grid-row: 3 / 4;
	font-size: 16px;
}

.event-container .lieu {
	grid-row: 4 / 5;
	align-self: start;
}

.event-container .questionnaire-container {
	grid-row: 5 / 6;
}

.questionnaire-container .nom-questionnaire,
.questionnaire-container .nom-evaluable {
	font-size: 14px;
}

.test-event-item{
	position: relative;
	display: grid;
	grid-template-columns: 1fr 50px max-content 1fr;
	grid-template-rows: 1fr min-content min-content min-content 1fr;
	width: 100%;
}
.test-event-item .image-container{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.test-event-item-titre{
	font-size: 18px;
	font-weight: 700;
	position: relative;
	z-index: 2;
	grid-column: 2 / 4;
}

.opacifier{

}
