.navigation-menu{
	/*grid-column: 2 / 3;*/
	/*grid-row: 1 / 3;*/
	align-self: start;
	margin-top: 50px;
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.navigation-menu .navigation-menu__item{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	width: max-content;
	cursor: pointer;
}

.navigation-menu__item .icon-wrapper {
	display: block;
	width: 44px;
	height: 44px;
	/*padding: 5px;*/
}
.navigation-menu__item svg {
	width: 100%;
	height: 100%;
	fill: white;
	padding: 5px;
}
.navigation-menu__item:hover svg {
	fill: black;
	background: #cfb77f;
}

.navigation-menu__item:hover .text-wrapper {
	text-decoration: underline;
	text-decoration-color: #cfb77f;
	text-underline-offset: 5px;
}
