input[name="jourselectionne"]:checked + label{
	background: #cfb77f;
	color: black;
}
.evenement-full-agenda {
	display: grid;
	gap: 20px;
	width: 100%;
	grid-template-columns: auto auto auto;
	grid-template-rows: 1fr minmax(120px, 160px) max-content max-content 1fr;
}

.evenement-full-agenda .image-event{
	grid-column: 1 / 4;
	grid-row: 2/3;
	/*max-height*/
}

.evenement-full-agenda .heure-event{
	grid-column: 1/2;
	grid-row: 3/4;
	justify-self: start;
	align-self: start;
}

.evenement-full-agenda .nom-evaluable-event{
	grid-column: 2/4;
	grid-row: 3/4;
	justify-self: start;
	align-self: start;
}

.evenement-full-agenda .bouton-evaluer{
	grid-column: 3/4;
	grid-row: 4/5;
	justify-self: end;
	align-self: end;
}

.evenement-full-agenda .lieu-event{
	grid-column: 1/3;
	grid-row: 4/5;
	justify-self: start;
	align-self: start;
}

@media screen and (min-width: 474px){
	.evenement-full-agenda{
		grid-template-columns: 60% auto;
		grid-template-rows: 1fr 25px 80px 45px auto 1fr;
	}

	.evenement-full-agenda .image-event{
		grid-column: 1 / 2;
		grid-row: 2/5;
		/*max-height*/
	}

	.evenement-full-agenda .heure-event{
		grid-column: 2/3;
		grid-row: 2/3;
		justify-self: start;
		align-self: start;
	}

	.evenement-full-agenda .nom-evaluable-event{
		grid-column: 2/3;
		grid-row: 3/4;
		justify-self: start;
		align-self: start;
	}

	.evenement-full-agenda .bouton-evaluer{
		grid-column: 2/3;
		grid-row: 4/5;
		justify-self: end;
		align-self: end;
	}

	.evenement-full-agenda .lieu-event{
		grid-column: 1/3;
		grid-row: 5/6;
		justify-self: start;
		align-self: start;
	}
}


.lien-vers-location-evenement:hover svg
{
	fill: #cfb77f;
}
.lien-vers-location-evenement:hover .adresse{
	text-underline-offset: 0.5em;
	text-decoration: underline;
	text-decoration-color: #cfb77f;
}


