.online-badge{
	z-index: 2;
	top: 20px;
	right: 20px;
	border-color: #cfb77f;
}
.is-online{
	background: #cfb77f;
}
.is-offline{
	background: transparent;
}


