form{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
}

form input[type=email],
form input[type=password],
form input[type=text]{
	/*border-radius: 5px;*/
	/*border: 1px solid grey;*/
	/*padding: 5px;*/
	width: 100%;
	max-width: 250px;
	outline: none;
}

form .login-password-wrapper{
	width: 100%;
	max-width: 250px;
}
/*form input[type=submit]{*/
/*	display: flex;*/
/*	flex-direction: column;*/
/*	justify-content: center;*/
/*	align-items: center;*/
/*	transition: background-color 200ms ease-in-out;*/
/*	margin: 40px auto;*/
/*	border-radius: 10px;*/
/*}*/

/*form input[type=submit]:hover{*/
/*	background: orangered;*/
/*}*/
