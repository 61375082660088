.toggle-menu{
	position: absolute;
	z-index: 3;
	top: 60px;
	right: 20px;
	display: grid;
	width: 44px;
	height: 44px;
	grid-template-columns: 44px;
	grid-template-rows: 44px;
	overflow: hidden;
	cursor: pointer;
	justify-items: center;
	align-items: center;
}

.icon-wrapper{
	width: 34px;
	height: 34px;
	grid-column: 1/2;
	grid-row: 1/2;
	transition: opacity 500ms ease-in-out;
	border: 1px solid #cfb77f;
	border-radius: 25px;
	padding: 5px;
}

.toggle-menu > .icon-wrapper{
	padding: 5px;
}

.icon-wrapper svg{
	width: 100%;
	height: 100%;
	fill: white;
}

.toggle-menu.menuin .menu-close,
.toggle-menu.menuout .menu-bars{
	opacity: 1;
}
.toggle-menu.menuout .menu-close,
.toggle-menu.menuin .menu-bars{
	opacity: 0;
}

.overlayed-menu{
	position: absolute;
	background: black;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	transition: transform 0.66s ease-in-out, opacity 0.16s ease-in-out 0.5s;
}

.overlayed-menu.menuout{
	transform: translateX(100%);
	opacity: 0.5;
}
.overlayed-menu.menuin{
	transform: translateX(0%);
	opacity: 1;
}

/*.navigation-menu__item .icon-wrapper svg{*/
/*	transition: border-radius 330ms ease-in-out;*/
/*}*/
.navigation-menu__item:hover .icon-wrapper svg {
	border-radius: 25px;
}
