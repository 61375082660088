.button{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: background-color 200ms ease-in-out;
	/*margin: 40px auto;*/
}
.button:hover{

}
